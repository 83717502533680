@tailwind base;
@tailwind components;
@tailwind utilities;

@supports (font-variation-settings: normal) {
  :root { font-family: InterVariable, sans-serif; }
}

body {
  margin: 0px;
  height: 100% !important;
  background-color: #f3f4f6;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Header */
.header-item-light {
  @apply flex flex-row items-center w-full px-4 py-3 rounded text-gray-800 hover:text-gray-800  hover:bg-gray-200;
}

.header-item-light.active {
  @apply bg-gray-200 border-b-4 font-bold text-gray-800 border-gray-800 hover:text-gray-800;
}

.header-item-dark {
  @apply flex flex-row items-center w-full px-4 py-3 rounded text-gray-100 border-gray-100 hover:text-gray-800 hover:bg-gray-100;
}

.header-item-dark.active {
  @apply  bg-gray-200  border-b-4 font-bold text-gray-800 border-gray-800 hover:text-gray-800;
}

.module-header-item {
  @apply py-2 flex px-2 rounded transition-colors duration-200;
}

.module-header-item:hover {
  @apply hover:rounded hover:bg-gray-200;
}

.module-header-item.active {
  @apply rounded border-b text-gray-800 font-medium border-b-4;
}

/* Footer */

.footer {
  @apply flex flex-col justify-between items-center p-4 space-y-4;
}

.footer-links {
  @apply flex flex-row space-x-8 text-xs text-[#393939];
}

.footer-trademark {
  @apply flex flex-row space-x-8 text-xs text-[#393939];
}

/* Main view structure */
/* .main {
  @apply flex flex-col max-w-full font-sans bg-gray-100 h-full min-h-full w-full overflow-x-hidden;
} */
.body-wrapper {
  @apply flex flex-col xl:flex-row gap-8 m-5 max-w-screen-2xl text-left
}

.left-big-view {
  @apply flex flex-col max-w-full w-full xl:w-2/3 gap-5;
}

.right-small-view {
  @apply flex flex-col w-full xl:w-1/3 gap-5;
}

/* Main text architecture */
.section-card {
  @apply flex flex-col items-start w-full
  gap-4 
  text-left text-[#393939] 
  bg-white border rounded-lg px-2 py-5 lg:p-8 lg:pb-12
  shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] ;
}

.section-card-right {
  @apply flex flex-col space-y-4 text-left text-[#393939] items-start bg-white border rounded-lg px-2 py-5 lg:p-8;
}

.title {
  @apply text-2xl lg:text-3xl  font-black text-[#393939];
}

.section-title {
  @apply text-xl lg:text-2xl  font-light text-[#393939];
}

.subsection-title {
  @apply text-lg lg:text-xl  font-semibold text-[#393939];
}

.card-title {
  @apply text-sm text-left lg:text-base  font-medium text-[#393939];
}

.modal-title {
  @apply text-xl lg:text-2xl  font-black text-[#393939];
}

/** Common themes
* Title
* Title-description - subtitle of each section
*/
.title-description {
  @apply text-sm text-gray-700 mb-4 text-left;
}

.form-label {
  @apply text-left block text-sm font-medium leading-6 text-gray-800;
}

.text-input {
  @apply text-xs text-gray-900 border border-gray-200 rounded p-2 focus:border-green-200 focus:outline-none;
}

.textarea-input {
  @apply text-xs text-gray-900 border border-gray-200 rounded p-2 focus:border-green-200 focus:outline-none;
}

.save-button {
  @apply px-4 py-2 rounded-lg text-sm cursor-pointer bg-green-200 hover:bg-green-400 shadow-xl
  transition-colors duration-300 ease-in-out
  focus:outline-none focus:ring-4 focus:ring-green-300 
  disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-not-allowed
  dark:bg-green-600 dark:hover:bg-green-800 dark:focus:ring-green-500
  dark:text-white dark:font-bold;
}

.remove-button {
  @apply bg-red-500 hover:bg-red-700 text-white font-medium rounded-lg text-sm sm:w-auto px-4 py-2 shadow-xl 
  text-center transition-colors duration-300 ease-in-out 
  focus:outline-none focus:ring-4 focus:ring-red-500 
  dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-700;
}

.secondary-button {
  /* @apply px-4 py-2 rounded-lg text-sm cursor-pointer */
  @apply flex flex-row items-center px-4 py-2 rounded-lg text-sm cursor-pointer font-medium text-gray-800 
  transition-colors duration-300 ease-in-out 
  /* focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2  */
  dark:bg-gray-900 dark:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-500;
}


.editor-buttons{
  @apply bg-gray-200 px-3 py-2 rounded-lg text-sm text-gray-800 m-1 hover:bg-gray-300 ;
}

.filter-selector {
  @apply text-sm text-gray-800 text-left;
}

.input-field {
  @apply text-xs text-gray-800 text-left border border-gray-300 rounded-lg shadow p-2 bg-white  focus:border-green-300 focus:outline-none;
  transition: border-color 0.3s ease;

  /* Accessibility improvements */
  &:focus {
    @apply ring-2 ring-green-300 ring-opacity-50;
  }

  /* Placeholder styling */
  &::placeholder {
    @apply text-gray-500;
  }

  /* Hover effect */
  &:hover {
    @apply border-gray-400;
  }

  /* Style for disabled inputs */
  &:disabled {
    @apply bg-gray-100 text-gray-600 cursor-not-allowed;
  }
}

/* React Data Grid */
.rdg {
  @apply w-full h-full text-xs border-t border-l border-gray-200 dark:border-gray-200 dark:text-gray-200 dark:bg-gray-900;
}
.rdg .rdg-header-row {
  @apply bg-gray-900 font-medium w-full dark:bg-gray-900;
}
.rdg .rdg-header-row .rdg-cell {
  @apply text-gray-100 border-white w-full dark:bg-gray-900 p-1 flex flex-row items-center justify-start;
}
.rdg .rdg-header-row .rdg-cell:hover {
  @apply bg-gray-600 dark:bg-gray-200 hover:text-gray-200;
}

.rdg .rdg-header-row .rdg-cell .rdg-header-sort-name {
  white-space: nowrap;
  overflow: hidden;
  padding-right: 4.5rem; /* small safe padding */
}
.rdg .rdg-header-row .rdg-cell .rdg-sort-arrow {
  margin-left: 0.25rem;
}

.rdg .rdg-cell {
  @apply text-gray-800 border-b border-r border-gray-200 dark:border-gray-200 dark:text-gray-200 dark:bg-gray-800 p-0;
}
.rdg .rdg-row:hover {
  @apply bg-gray-100 dark:bg-gray-200 hover:text-gray-800;
}
.rdg .rdg-cell:hover {
  @apply bg-gray-100 dark:bg-gray-200 hover:text-gray-800;
}
.rdg .rdg-text-editor {
  @apply text-gray-800 border border-gray-200 rounded p-2 focus:border-green-200 focus:outline-none dark:text-white;
}
.custom-header-style {
  background-color: #626364; /* Cornflower blue background */
  color: white; /* White text color */
  font-weight: bold;
}
.empty-header-style {
  background-color: #ffffff; /* Cornflower blue background */
  color: white; /* White text color */
}


/* Heatmap hint */
.hint-box {
  @apply text-xs text-gray-800 text-left bg-gray-200 p-2 rounded-lg;
}

.hint {
  background: rgba(0,0,0,0.8);
  color: #fff;
  padding: 5px 10px;
  border-color: #626364;
  border-radius: 6px;
  font-size: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Calendar */
.calendar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: auto;
}
.calendar-navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  padding-inline: 16px;
}

.calendar-header {
  font-size: 24px;
  margin-bottom: 20px;
}

.calendar-body {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
}

.calendar-day-header {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  /* padding: 10px; */
}

.calendar-day-label {
  flex: 1;
  text-align: center;
  font-weight: bold;
  font-size:small;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr); /* 7 days in a week */
  /* gap: 5px; */
  padding: 10px;
}

.calendar-day {
  width: 35px;
  height: 35px;
  padding: 5px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
  /* border: 1px solid #e0e0e0;
  border-radius: 35px; */
  font-size: 14px;
  font-weight: semibold;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: box-shadow 0.3s ease;
  cursor: pointer;
}

.calendar-day:hover {
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.414); */
  background-color: #f3f4f6;
  color: #3e3e3e;
  border-radius: 8px;
}

.highlighted {
  background-color: #BBF7D0; /* Or any color for highlighting */
  border: #BBF7D0;
  border-width: 4px;
  border-radius: 8px;
}

.pending {
  background-color: #ffe96e; /* Or any color for highlighting */
  border: #ffe96e;
  border-width: 4px;
  border-radius: 8px;
}

.weekend {
  /* background-color: #cecece;  */

}

.calendar-event {
  background-color: #3e3e3e; /* Or any color for highlighting */
  border: #3e3e3e;
  color:#ffffff;
  font-weight: bold;
  border-width: 4px;
  border-radius: 8px;
}

.events-list {
  margin-top: 20px;
  width: 85%;
}

/* Tooltip */
.tooltip-content {
  /* text -sm */
  font-size: 0.75rem;
  line-height: 1rem;
  position: absolute;
  background-color: rgb(55 65 81);
  color: white;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 50;
  bottom: 115%;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 300ms ease-in-out;
  opacity: 0;
  margin-bottom: 8px;
  width: max-content;

  /* Arrow */
  &::after {
    content: "";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    border-width: 5px;
    border-style: solid;
    border-color: rgb(55 65 81) transparent transparent transparent;
    transform: translateX(-50%);
  }
}

.group:hover .tooltip-content {
  position: absolute;
  opacity: 1;
}

/* Loader container */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 40px;
  padding: 12px;
}

.loader-inner {
  /* bottom: 0; */
  height: 60px;
  /* left: 0; */
  margin: auto;
  position: absolute;
  /* right: 0; */
  /* top: 0; */
  width: 100px;
}

.loader-line-wrap {
  animation: 
  spin 2000ms cubic-bezier(.175, .885, .32, 1.275) infinite
;
  box-sizing: border-box;
  height: 50px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transform-origin: 50% 100%;
  width: 100px;
}
.loader-line {
  border: 4px solid transparent;
  border-radius: 100%;
  box-sizing: border-box;
  height: 100px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100px;
}
.loader-line-wrap:nth-child(1) { animation-delay: -50ms; }
.loader-line-wrap:nth-child(2) { animation-delay: -100ms; }
.loader-line-wrap:nth-child(3) { animation-delay: -150ms; }
.loader-line-wrap:nth-child(4) { animation-delay: -200ms; }
.loader-line-wrap:nth-child(5) { animation-delay: -250ms; }

.loader-line-wrap:nth-child(1) .loader-line {
  border-color: #BBF7D0;
  height: 90px;
  width: 90px;
  top: 7px;
}
.loader-line-wrap:nth-child(2) .loader-line {
  border-color: #BBF7D0;
  height: 76px;
  width: 76px;
  top: 14px;
}
.loader-line-wrap:nth-child(3) .loader-line {
  border-color: #6BAFAC;
  height: 62px;
  width: 62px;
  top: 21px;
}
.loader-line-wrap:nth-child(4) .loader-line {
  border-color:#518B94;
  height: 48px;
  width: 48px;
  top: 28px;
}
.loader-line-wrap:nth-child(5) .loader-line {
  border-color: #3E6977;
  height: 34px;
  width: 34px;
  top: 35px;
}

@keyframes spin {
  0%, 15% {
  transform: rotate(0);
}
100% {
  transform: rotate(360deg);
}
}


@layer base {
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}
